import React from "react";

const transactionsList = [
  {
    amount: 70.04,
    date: "12 Oct 2023",
    type: "Phone Credit",
    status: "Completed",
    method: "paypal",
  },

  {
    amount: 40.04,
    date: "12 Oct 2023",
    type: "Phone Credit",
    status: "fail",
    method: "Mtn",
  },

  {
    amount: 55.04,
    date: "12 Oct 2023",
    type: "Phone Credit",
    status: "Completed",
    method: "Vodafone",
  },
  {
    amount: 55.04,
    date: "12 Oct 2023",
    type: "Mobile Money",
    status: "fail",
    method: "Tigo",
  },
  {
    amount: 555.04,
    date: "12 Oct 2023",
    type: "Transfer",
    status: "Completed",
    method: "Simply",
  },
];

// import Router from "next/router";

export default function TransactionsListMobile({ transactionsList, isValidating, isFromHomePage, user }) {
  // const MethodImage = {
  //   PAYPAL: <img src="/static/paypal.png" alt="" />,
  //   MTN: <img src="/static/mtn-logo.png" alt="" />,
  //   TIGO: <img src="/static/tigo-logo.png" alt="" />,
  //   VODAFONE: <img src="/static/vodafone-logo.png" alt="" />,
  //   simply: <img src="/static/vodafone-logo.png" alt="" />,
  // };

  const MethodImage = {
    PAYPAL: <img src="/static/paypal.png" alt="" />,
    MTN: <img src="/static/mtn-logo.png" alt="" />,
    "MTN Mobile": <img src="/static/mtn-logo.png" alt="" />,

    TIGO: <img src="/static/tigo-logo.png" alt="" />,
    VODAFONE: <img src="/static/vodafone-logo.png" alt="" />,
    simply: <img src="/static/simplytransfer.png" alt="" />,
  };

  const currentUserId = user?.uuid;

  const is_simply_transaction = (type) => {
    return type === "simply";
  };

  const is_user_payer = (transaction) => {
    return transaction?.payer?.uuid === currentUserId;
  };

  return (
    <section className="transactionslistmobile">
      <div className="transactionslistmobile__container">
        {/* {isValidating ? <ListLoader /> : null} */}

        <ul>
          {transactionsList?.map((transacton, i) => {
            return (
              <li key={i}>
                <div className="transactionslistmobile__middle">
                  <div className="transactionslistmobile__middleimg">{MethodImage[transacton.payment_method]}</div>
                  <div className="transactionslistmobile__info">
                    <p className="transactionslistmobile__type">
                      {/* {transacton.channel?.replace("_", " ") || "N/A"} */}

                      {transacton.channel.split("")[0]?.toUpperCase()}
                      {transacton.channel?.slice(1)?.replace("_", " ") || "N/A"}
                    </p>
                    <div className="transactionslistmobile__buttom">
                      <span className="transactionslistmobile__created_at">
                        {" "}
                        {/* {transacton.created_at?.split("T")[0]} */}
                        {transacton.created_at_arrow}
                      </span>{" "}
                      <span className={`transactionslistmobile__status ${transacton.status?.toLowerCase()}`}>
                        {transacton.status}
                      </span>
                    </div>
                  </div>
                </div>

                <p>
                  {/* {transacton.transaction_type === "payout" ? "- " : "+ "} */}
                  {is_simply_transaction(transacton.payment_method) && is_user_payer(transacton) ? "- " : "+ "}
                  {user?.currency_symbol} {transacton.amount}
                </p>
              </li>
            );
          })}

          {/* <li>
            <div className="transactionslistmobile__middle">
              <div className="transactionslistmobile__middleimg">
                <img src="/static/paypal.png" alt="" />
              </div>{" "}
              <div className="transactionslistmobile__info">
                <p className="transactionslistmobile__type">Mobile Money</p>
                <div className="">
                  <span>08:23AM</span> <span>Complete</span>
                </div>
              </div>
            </div>

            <p>-59</p>
          </li>

          <li>
            <div className="transactionslistmobile__middle">
              <div className="transactionslistmobile__middleimg">
                <img src="/static/simply.png" alt="" />
              </div>

              <div className="transactionslistmobile__info">
                <p className="transactionslistmobile__type">Boma Sula</p>
                <div className="">
                  <span>08:23AM</span> <span>Complete</span>
                </div>
              </div>
            </div>

            <p>-30</p>
          </li>

          <li>
            <div className="transactionslistmobile__middle">
              <div className="transactionslistmobile__middleimg">
                <img src="/static/paypal.png" alt="" />
              </div>
              <div className="transactionslistmobile__info">
                <p className="transactionslistmobile__type">Airtime</p>
                <div className="">
                  <span>08:23AM</span> <span>Complete</span>
                </div>
              </div>
            </div>

            <p>-10</p>
          </li> */}
        </ul>
      </div>
    </section>
  );
}
