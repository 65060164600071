import React from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Mousewheel } from "swiper";
import { useSelector } from "react-redux";

const Widgets = ({ data }) => {
  console.log(data, "datadatadata");

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));

  return (
    <React.Fragment>
      <Col xxl={4} md={4}>
        <Card className="card-animate">
          <CardBody>
            {/* <div className="d-flex mb-3"> */}
            {/* <div className="flex-grow-1">
                <lord-icon
                  src="https://cdn.lordicon.com/fhtaantg.json"
                  trigger="loop"
                  colors="primary:#405189,secondary:#0ab39c"
                  style={{ width: "55px", height: "55px" }}
                ></lord-icon>
              </div> */}
            {/* <div className="flex-shrink-0">
                <p className="mb-0 text-muted">
                  <span className="badge bg-light text-success mb-0">
                    <i className="ri-arrow-up-line align-middle"></i> 7.05 %
                  </span>{" "}
                  vs. previous week
                </p>
              </div> */}
            {/* </div> */}
            <h3 className="mb-2">
              <span className="counter-value" data-target="748">
                {user?.currency_symbol}
                <CountUp start={0} end={data?.first} separator="," prefix="" duration={1} decimals={2} />
              </span>
              {/* <small className="text-muted fs-13">.68</small> */}
            </h3>
            <h6 className="text-muted mb-0">Total School fees paid First semester</h6>
          </CardBody>
        </Card>
      </Col>

      <Col xxl={4} md={4}>
        <Card className="card-animate">
          <CardBody>
            {/* <div className="d-flex mb-3"></div> */}
            <h3 className="mb-2">
              <span className="counter-value" data-target="748">
                {user?.currency_symbol}{" "}
                <CountUp start={0} end={data?.second} separator="," prefix="" duration={1} decimals={2} />
              </span>
            </h3>
            <h6 className="text-muted mb-0">Total School fees paid Second semester</h6>
          </CardBody>
        </Card>
      </Col>
      <Col xxl={4} md={4}>
        <Swiper
          slidesPerView={1}
          spaceBetween={24}
          mousewheel={true}
          loop={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Mousewheel]}
          className="default-swiper rounded"
        >
          <SwiperSlide>
            <Card className="card-animate">
              <div className="card-body bg-soft-info">
                {/* <div className="d-flex mb-3">
                  <div className="flex-grow-1">
                    <lord-icon
                      src="https://cdn.lordicon.com/vaeagfzc.json"
                      trigger="loop"
                      colors="primary:#405189,secondary:#0ab39c"
                      style={{ width: "55px", height: "55px" }}
                    ></lord-icon>
                  </div>
                </div> */}
                <h3 className="mb-2">
                  {user?.currency_symbol} {data?.third}
                  {/* <small className="text-muted fs-13">.36</small> */}
                </h3>
                <h6 className="text-muted mb-0">Total School fees paid Third semester</h6>
              </div>
            </Card>
          </SwiperSlide>
        </Swiper>
      </Col>
    </React.Fragment>
  );
};

export default Widgets;
