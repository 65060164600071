import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import VisaCard from "./Cards/VisaCard";
import MasterCard from "./Cards/MasterCard";
// import BalanceCard from "./Cards/BalanceCard";
import DashedlineChart from "./Charts/DashedlineChart";
import Balancecardv2 from "./Cards/BalanceCardV2";
import { APIClient } from "../utils/api_helper";
import useSWR from "swr";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

import BillsItems from "../apps/Paybill/BillsItems";
import PatternedDonut from "./Charts/PatternedDonut";
import SimplePie from "./Charts/SimplePie";
import deposit from "../assets/icons/deposit.svg";
import withdraw from "../assets/icons/withdraw.svg";

// import receiveImg from "/static/icons/money.png";

const api = new APIClient();

const fetcher = async (url) => {
  const { data } = await api.get(url);

  return data;
};

export default function DashboardAccount({ walletBalance, isValidating, mutate }) {
  let {
    data,
    error,
    isValidating: isValidatingExpense,
    mutate: mutateExpense,
  } = useSWR(`/transaction/expense/`, fetcher, {
    revalidateOnFocus: false, // revalidate when the page gains focus
    revalidateOnReconnect: false, // revalidate when the browser regains network connectivity
  });

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));

  const spendingReportings = data?.payin_stats;
  const last_seven_days_total = data?.payin_stats?.last_seven_days_total;

  console.log(data, "EXPENSE__dATA");

  return (
    <section className="dashboardaccount">
      <div className="dashboardaccount__container">
        <div className="dashboardaccount__summary">
          <Row>
            <Col md={6}>
              <Balancecardv2
                walletBalance={walletBalance}
                isValidating={isValidating}
                mutate={() => mutate(mutateExpense)}
              />
            </Col>

            <Col md={6}>
              <div className={"balancecardv2 quick card-animate " + "primary"}>
                <Card className="balancecardv2__container quick">
                  <div className="balancecardv2__list quick__list">
                    <div className="balancecardv2__top">
                      <p className={"balancecardv2__top--text text-uppercase fw-medium mb-0 text-" + "muted"}>
                        Quick Actions
                      </p>

                      <div className="balancecardv2__cardcurrency">
                        {" "}
                        <div className="flex-shrink-0 align-self-end">
                          <span className={"badge badge-soft-success"}>
                            <i className={"align-middle me-1 ri-arrow-up-s-fill"}></i>
                            3.5%<span></span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <h4 className={"fs-22 fw-semibold d-flex align-items-center justify-content-space-between"}>
                      <span className="counter-value" data-target="559.25">
                        <CountUp
                          start={0}
                          // prefix={"<span>&#8373;</span>"}
                          prefix={user?.currency_symbol || ""}
                          separator={","}
                          end={last_seven_days_total}
                          decimals={2}
                          duration={4}
                        />
                      </span>
                      <p className="fs-10 mx-3 mb-0"> last 7 days transactions</p>
                    </h4>

                    <div className="balancecardv2__analytics">
                      <ul className="mb-0">
                        <li>
                          <Link to="/send-money" className="fw-semibold text-primary">
                            <div className="flex-shrink-0 text-align-center">
                              <figure>
                                <img
                                  src={"/static/icons/send.png"}
                                  alt=""
                                  style={{
                                    filter:
                                      "invert(99%) sepia(0%) saturate(5630%) hue-rotate(246deg) brightness(111%) contrast(148%)",
                                  }}
                                />
                              </figure>

                              <span className="quick">Send money</span>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="/requests" className="fw-semibold text-primary">
                            <div className="flex-shrink-0 text-align-center">
                              <figure>
                                <img
                                  src={"/static/icons/money.png"}
                                  alt=""
                                  style={{
                                    filter:
                                      "invert(99%) sepia(0%) saturate(5630%) hue-rotate(246deg) brightness(111%) contrast(148%)",
                                  }}
                                />
                              </figure>

                              <span className="quick">Request</span>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* </div> */}
                </Card>
              </div>
            </Col>
          </Row>

          <DashedlineChart spendingReportings={spendingReportings} />
        </div>

        <Card className="ribbon-box right overflow-hidden">
          <div className="dashboardaccount__savedcards">
            <div className="dashboardaccount__head">
              {/* <p>Pay bills</p> */}

              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Quickly pay your bills</h4>
                <div className="flex-shrink-0"></div>
              </CardHeader>

              <img src="/static/icons/bills.png" alt="" />
            </div>

            <li className="savedcards__">
              {/* <VisaCard />
            <MasterCard /> */}

              <BillsItems xl="6" />
            </li>
          </div>

          <PatternedDonut dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]' />
        </Card>
      </div>
    </section>
  );
}
