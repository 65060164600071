import React from "react";

// const transactionsList = [
//   {
//     amount: 70.04,
//     date: "12 Oct 2023",
//     type: "Phone Credit",
//     status: "Completed",
//     method: "paypal",
//   },

//   {
//     amount: 40.04,
//     date: "12 Oct 2023",
//     type: "Phone Credit",
//     status: "fail",
//     method: "Mtn",
//   },

//   {
//     amount: 55.04,
//     date: "12 Oct 2023",
//     type: "Phone Credit",
//     status: "Completed",
//     method: "Vodafone",
//   },
//   {
//     amount: 55.04,
//     date: "12 Oct 2023",
//     type: "Mobile Money",
//     status: "fail",
//     method: "Tigo",
//   },
//   {
//     amount: 555.04,
//     date: "12 Oct 2023",
//     type: "Transfer",
//     status: "Completed",
//     method: "Simply",
//   },
// ];
// import Router from "next/router";

export default function TransactionsList({ transactionsList, isValidating, user, isFromHomePage, backgroundColor }) {
  const MethodImage = {
    PAYPAL: <img src="/static/paypal.png" alt="" />,
    MTN: <img src="/static/mtn-logo.png" alt="" />,
    "MTN Mobile": <img src="/static/mtn-logo.png" alt="" />,

    TIGO: <img src="/static/tigo-logo.png" alt="" />,
    VODAFONE: <img src="/static/vodafone-logo.png" alt="" />,
    simply: <img src="/static/simplytransfer.png" alt="" />,
  };

  const handleGoToTransaction = () => {
    localStorage.setItem("currentPage", "Transaction");
    // Router.push("/transactions");
  };

  const currentUserId = user?.uuid;

  const is_simply_transaction = (type) => {
    return type === "simply";
  };

  const is_user_payer = (transaction) => {
    return transaction?.payer?.uuid === currentUserId;
  };

  return (
    <section className={`transactions ${backgroundColor}`}>
      <div className="transactions__container">
        {/* {isFromHomePage ? (
          <div className="transactions__homeheader">
            <div className="transactions__homeheader--container">
              <span>Recent Transactions</span>
              <div className="transactions__homeheader--left">
                <p onClick={handleGoToTransaction}>View All</p>
              </div>
            </div>
          </div>
        ) : null} */}

        <ul className="transactions__list">
          <li className="transactions__item head">P.Method</li>

          <li className="transactions__item head">Amount</li>
          <li className="transactions__item head">Date</li>
          <li className="transactions__item head">Type</li>
          <li className="transactions__item head status">
            <span>Status</span>
          </li>
        </ul>

        {transactionsList?.map((transacton, i) => {
          return (
            <div key={i} className="transactions__listcontainer">
              <ul className="transactions__list">
                <li className="transactions__item pethod">
                  {MethodImage[transacton.payment_method]}
                  <span>{transacton.payment_method}</span>
                </li>

                <li className="transactions__item amount">
                  {is_simply_transaction(transacton.payment_method) && is_user_payer(transacton) ? "-" : "+"}
                  {/* {transacton.transaction_type === "payout" ? "-" : "+"}{" "} */}
                  {user?.curency_symbol}
                  {transacton.amount}
                </li>
                <li className="transactions__item date">
                  {/* {transacton.created_at?.split("T")[0]} */}
                  {transacton.created_at_arrow}
                </li>
                <li className="transactions__item type">{transacton.channel?.replace("_", " ") || "N/A"}</li>
                <li className="transactions__item status">
                  <p className={`${transacton.status?.toLowerCase()}`}>
                    {transacton.status?.split("")[0]?.toUpperCase()}
                    {transacton.status?.slice(1)}
                  </p>
                </li>
              </ul>
              <li className={`transactions__item actions ${"disable-li"}`}>...</li>
            </div>
          );
        })}

        {/* <li className="transactions__item amount">$70.04</li>
          <li className="transactions__item date">12 Oct 2023</li>
          <li className="transactions__item type">Phone credit</li>
          <li className="transactions__item status">Completed</li> */}
      </div>
    </section>
  );
}
