import React, { useEffect, useMemo, Fragment, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
  Form,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  Button,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { debounce } from "lodash";

import classnames from "classnames";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/RequestsTableContainr";

import { FromCol, ToCol, DetailsCol, TransactionID, TypeCol, Status } from "./TransactionsCol";
import RequestStatusModal from "./RequestStatusModal";
import RequestFilter from "./RequestFilter";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

import { APIClient } from "../../utils/api_helper";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../Components/Common/MsgToast";
import CustomWebcam from "./Webcam";
import ListLoader from "../../Components/modules/ListLoader";
import { useLocation } from "react-router-dom";
import EmptyList from "../../Components/EmptyList";

import { useSearchParams, useNavigate } from "react-router-dom";
import RefreshButton from "../../Components/Loaders/RefreshButton";
import RequestModal from "./Modal/RequestModal";

const api = new APIClient();

const AllRequests = ({ data, isValidating, mutate }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrorMsg] = useState("");
  const [request, setRequest] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const [isSendRequestModalOpen, setIsSendRequestModalOpen] = useState(false);

  const sendRequestModalOptions = {
    isOpen: isSendRequestModalOpen,
    setIsOpen: setIsSendRequestModalOpen,
  };

  const [requestSuccess, setRequestSuccess] = useState("");
  const [requestRecipient, setRequestRecipient] = useState({}); //

  //

  const [responseStatus, setResponseStatus] = useState("");

  const [isInfoDetails, setIsInfoDetails] = useState(false);

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleApproval = (request, status) => {
    console.log("CLIIICKED", request);

    setResponseStatus(status);

    setRequest(request);

    toggleModal();
  };

  // Custom Hover Tabs
  const [customHoverTab, setcustomHoverTab] = useState("1");
  const customHovertoggle = (tab) => {
    if (customHoverTab !== tab) {
      setcustomHoverTab(tab);
    }
  };

  const [alert, setAlert] = useState({
    status: false,
    title: "Confirming...",
    message: "Confirming user...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-primary",
  });

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      amount: "",
      recipient: "",
      requester_cam: "",
    },

    validationSchema: Yup.object({
      amount: Yup.string().required("Please enter an amount"),
      recipient: Yup.string().required("Please enter the recipient"),
      requester_cam: Yup.string().required("Please add your cam photo"),
    }),

    onSubmit: async (values) => {
      try {
        setAlert({ status: false });

        setAlert({
          ...alert,
          status: true,
          title: "Sending...",
          message: "Sending request. please wait...",
          class: "spinner-border spinner-border-lg",
          textClass: "text-primary",
        });
        console.log(values, "VALUESS__REQUESTTS");
        setSuccessMsg("");

        setErrorMsg("");

        const result = values.amount.replace(/[^0-9.]/g, "");

        values.amount = result;

        const data = await api.post("/transaction/charge/simply/v2/", values);

        setSuccessMsg(data.message);

        console.log(data, "REQUEST__STATS__DATAA");

        setIsSendRequestModalOpen(!isSendRequestModalOpen);
        setAlert({
          ...alert,
          status: false,
        });
        mutate();

        setTimeout(() => {
          setSuccessMsg("");
        }, 2000);
      } catch (error) {
        setAlert({
          ...alert,
          status: false,
        });

        console.log(error, "LOGIN");

        let errMsg = error?.response?.data?.message;

        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";

        setErrorMsg(errMsg);

        setTimeout(() => {
          setErrorMsg("");
        }, 3000);

        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  console.log(validation.values, "VALL__VALUES");

  console.log(validation.errors, "VALL__ERRORS");
  const columns = useMemo(
    () => [
      {
        Header: "Reference",
        accessor: "reference",
        filterable: true,
      },

      {
        Header: "From",
        accessor: "requester.first_name",
        filterable: true,
        Cell: (cellProps) => {
          const name =
            cellProps.row.original?.requester?.first_name + " " + cellProps.row.original?.requester?.last_name;

          const requester = cellProps.row.original.requester.uuid;
          const userId = user?.uuid;

          const isCurrentUserRequester = requester === userId;

          const className = isCurrentUserRequester ? "badge badge-soft-primary" : "badge badge-soft-secondary";

          console.log(name, "NAMEEEEEEEEEEEEEEEEEEEE");

          return (
            <React.Fragment>
              <span className={`${className} text-uppercase text-uppercase fw-bold fs-10`}>
                {isCurrentUserRequester ? "You" : name}
              </span>
            </React.Fragment>
          );
        },
      },
      {
        Header: "To",
        accessor: "requestee.first_name",
        filterable: true,
        Cell: (cellProps) => {
          const name =
            cellProps.row.original?.requestee?.first_name + " " + cellProps.row.original?.requestee?.last_name;

          const requester = cellProps.row.original.requestee.uuid;
          const userId = user?.uuid;

          const isCurrentUserRequestee = requester === userId;

          const className = isCurrentUserRequestee ? "badge badge-soft-primary" : "badge badge-soft-secondary";

          return (
            <React.Fragment>
              <span className={`${className} text-uppercase fw-bold fs-10`}>
                {isCurrentUserRequestee ? "You" : name}
              </span>

              {/* <span className="fw-bold fs-20" id="">
                {request?.requestee?.first_name} {request?.requestee?.last_name}
              </span> */}
            </React.Fragment>
          );
        },
      },
      {
        Header: "Amount",
        Cell: (transaction) => {
          const requester = transaction.row.original.requester.uuid;
          const userId = user?.uuid;

          const isCurrentUserRequester = requester === userId;

          return (
            <>
              <h6 className={`text amount mb-1  ${isCurrentUserRequester ? "btn-ghost-success" : "btn-ghost-danger"}`}>
                {isCurrentUserRequester ? "+" : "-"}
                {user?.currency_symbol} {transaction.row.original.amount}
              </h6>
              <p className="text-muted mb-0">{transaction.row.original.amount1}</p>
            </>
          );
        },
      },

      {
        Header: "Time",
        accessor: "created_at_arrow",
      },
      {
        Header: "Date created",
        filterable: true,
        Cell: (cellProps) => {
          const approved = cellProps.row.original?.date_joined;
          return (
            <React.Fragment>
              <span className="">{approved}</span>
            </React.Fragment>
          );
        },
      },

      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: (cellProps) => {
          const status = cellProps.row.original?.status;
          return (
            <React.Fragment>
              {status === "successful" ? (
                <span className="badge badge-soft-success text-uppercase">{status}</span>
              ) : status === "pending" ? (
                <span className="badge badge-soft-warning text-uppercase">{status}</span>
              ) : status === "rejected" ? (
                <span className="badge badge-soft-danger text-uppercase">{status}</span>
              ) : status === "cancelled" || status === "fail" ? (
                <span className="badge badge-soft-danger text-uppercase">{status}</span>
              ) : null}
            </React.Fragment>
          );
        },
      },
      {
        Header: "Actions",
        Cell: (cellProps) => {
          const request = cellProps.row.original;
          const requestId = cellProps.row.original.uuid;

          const status = cellProps.row.original.status;

          const requester = cellProps.row.original.requester.uuid;
          const userId = user?.uuid;

          const isCurrentUserRequester = requester === userId;

          return (
            <ul className="list-inline hstack gap-3 mb-0">
              {status !== "pending" ? (
                ""
              ) : (
                <Fragment>
                  {isCurrentUserRequester ? (
                    <Fragment>
                      <li className="list-inline-item edit me-0" title="Make Payment and Approve">
                        <Link
                          to="#"
                          onClick={() => handleApproval(request, "cancelled")}
                          type="button"
                          className="d-inline-block border-2 btn btn-outline-warning py-1"
                        >
                          <i className="ri-plus-fill align-middle text-muted" /> Cancel
                        </Link>
                      </li>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <li className="list-inline-item edit me-0" title="Make Payment and Approve">
                        <Link
                          to="#"
                          onClick={() => handleApproval(request, "approved")}
                          type="button"
                          className="d-inline-block border-2 btn btn-outline-success py-1"
                        >
                          <i className="ri-plus-fill align-middle text-muted" /> Approve
                        </Link>
                      </li>
                      <li className="list-inline-item edit me-0" title="Make Payment and Approve">
                        <Link
                          to="#"
                          onClick={() => handleApproval(request, "rejected")}
                          type="button"
                          className="d-inline-block border-2 btn btn-outline-danger py-1"
                        >
                          <i className="ri-plus-fill align-middle text-muted" /> Decline
                        </Link>
                      </li>
                    </Fragment>
                  )}
                </Fragment>
              )}

              <li className="list-inline-item edit">
                <UncontrolledDropdown className="z-3">
                  <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                    <i className="ri-more-fill fs-14 align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <Link to={`/request/${requestId}`}>
                      <DropdownItem className="dropdown-item remove-item-btn text-muted">
                        <i className="ri-add-fill align-middle me-2 fs-16 text-muted" /> View
                      </DropdownItem>
                    </Link>
                    {/* 
                    <DropdownItem
                      className="dropdown-item remove-item-btn text-muted"
                      onClick={() => {
                        const memberData = cellProps.row.original;
                        // onClickDelete(memberData);
                      }}
                    >
                      <i className="ri-delete-bin-fill align-middle me-2 fs-16 text-muted"></i> Delete
                    </DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [user]
  );

  const updateQueryParam = (value, name) => {
    // Create a new URLSearchParams object from the current searchParams
    const newSearchParams = new URLSearchParams(searchParams);

    // Delete existing parameters
    newSearchParams.delete("offset");
    newSearchParams.delete("limit");

    // Set the new parameter
    newSearchParams.set(name, value);

    // Update the URL with the new search parameters
    navigate({ search: newSearchParams.toString() });
  };

  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails);
  };

  const handler = useCallback(
    debounce((value) => {
      console.log(value, "SEARCHE_vALUEE");
      updateQueryParam(value, "reference");
    }, 1500),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    handler(value);
  };

  return (
    <React.Fragment>
      <Row className="align-items-center mb-4 g-3">
        <div className="col-sm-auto ms-auto">
          <div className="d-flex gap-2">
            <button
              onClick={() => setIsSendRequestModalOpen(!isSendRequestModalOpen)}
              type="button"
              className="btn btn-primary btn-label right ms-auto nexttab"
            >
              <i className="ri-arrow-left-right-line label-icon align-middle fs-16 ms-2"></i>
              REQUEST MONEY
            </button>
          </div>
        </div>
      </Row>

      <Card>
        <CardHeader>
          <Row className="align-items-center g-3">
            <Col md={3}>
              <h5 className="card-title mb-0">All Payment requests</h5>
            </Col>
            <div className="col-md-auto ms-auto">
              <div className="d-flex gap-2">
                <RefreshButton isValidating={isValidating} mutate={mutate} />

                <div className="search-box">
                  <input
                    onChange={(e) => {
                      handleFieldSearch(e);
                    }}
                    type="text"
                    className="form-control search"
                    placeholder="Search for requests by reference..."
                  />
                  <i className="ri-search-line search-icon"></i>
                </div>
                <button onClick={toggleInfo} className="btn btn-soft-success">
                  <i className="ri-equalizer-line align-bottom me-1"></i>
                  Filters
                </button>
              </div>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <TableContainer
            columns={columns}
            data={data?.data || []}
            isGlobalFilter={false}
            isAddUserList={false}
            customPageSize={data?.requests_count}
            className="custom-header-css"
            divClass="table-responsive table-card mb-4"
            tableClass="align-middle table-nowrap mb-0"
            theadClass="table-light table-nowrap"
            thClass="table-light text-muted"
            customFetchData={() => console.log("yesss")}
            pagination
          />

          {isValidating ? <ListLoader /> : null}

          {!isValidating && !data?.data.length ? <EmptyList text="No payment requests found yet!" /> : null}
        </CardBody>
      </Card>

      <RequestStatusModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        request={request}
        responseStatus={responseStatus}
      />

      <RequestModal validation={validation} modalState={sendRequestModalOptions} mutate={mutate} />

      {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}

      <RequestFilter show={isInfoDetails} onCloseClick={() => setIsInfoDetails(false)} />
    </React.Fragment>
  );
};

export default AllRequests;
