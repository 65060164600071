import { Link } from "react-router-dom";

import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

import React, { useState } from "react";
import { APIClient } from "../../utils/api_helper";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../Components/Common/MsgToast";
import ProccessingLoader from "../../Components/Loaders/ProccessingLoader";
import { useSelector } from "react-redux";

const api = new APIClient();

const SendMoneyModal = ({ mutate, isModalOpen, toggleModal, responseStatus, simplyRecipient, simplyValidation }) => {
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrorMsg] = useState("");

  const [alert, setAlert] = useState({
    status: false,
    title: "Proccessing...",
    message: "Proccessing please wait...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-primary",
  });

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));

  // events validation
  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      recipient: simplyValidation.values.recipient,
      amount: simplyValidation.values.amount,
      password: "909090",
    },

    validationSchema: Yup.object({
      recipient: Yup.string().required("Please enter your event name"),
      amount: Yup.string().required("Please select your status"),
      password: Yup.string().required("Please select your status"),
    }),

    onSubmit: async (values) => {
      try {
        setAlert({
          status: true,
          title: "Proccessing...",
          message: "Proccessing please wait...",
          class: "spinner-border spinner-border-lg",
          textClass: "text-primary",
        });

        setErrorMsg("");
        setSuccessMsg("");
        console.log(values, "VALUESS__REQUESTTS");
        const data = await api.post("/transaction/transfer/simply/", values);
        console.log(data, "REQUEST__STATS__DATAA");

        // setRequestSuccess(data.message);
        mutate();

        setSuccessMsg(data.message);
        setErrorMsg("");

        simplyValidation.resetForm();

        toggleModal();

        setAlert({
          status: true,
          title: (
            <span className={""}>
              <i className={`text-success bx`}>TRANSFER SUCCESFULL</i>
            </span>
          ),

          message: data.message,
          // class: "spinner-border spinner-border-lg",
          back_url: "/deposit-money",
          goto_url: "/transactions",
          icon: (
            <lord-icon
              src="https://cdn.lordicon.com/tqywkdcz.json"
              style={{ width: "150px", height: "150px" }}
              trigger="loop"
              colors="primary:#f7b84b,secondary:#405189"
            ></lord-icon>
          ),
        });
        // setTimeout(() => {
        //  }, 2000);
      } catch (error) {
        setAlert({ ...alert, status: false });

        console.log(error, "LOGIN");
        setErrorMsg("");

        let errMsg = error?.response?.data?.message;

        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";

        setErrorMsg(errMsg);

        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  return (
    <React.Fragment>
      {/* Position Top */}
      <Modal
        id="topmodal"
        isOpen={isModalOpen}
        toggle={() => {
          toggleModal();
        }}
      >
        <ModalHeader
          className="modal-title"
          id="myModalLabel"
          toggle={() => {
            // tog_positionTop();
            toggleModal();
          }}
        >
          {responseStatus === "rejected" ? "Reject payment request" : ""}
          {responseStatus === "approved" ? "Reject payment request" : ""}
          {responseStatus === "cancelled" ? "cancel payment request" : ""}
        </ModalHeader>
        <ModalBody className="text-center p-5">
          <div className="mt-4">
            <h4 className="mb">
              <p>
                Confirm payment of{" "}
                <span className="d-inline-block   btn-outline-success py-1 ">
                  {user?.currency_symbol} {simplyValidation.values.amount}
                </span>{" "}
                to
              </p>
            </h4>

            <h4 className="mb-3">
              <img className="rounded-circle avatar-xl" src={simplyRecipient?.face_id} width="200" />
              <div className="btn-outline-info py-1">
                {simplyRecipient?.first_name} {simplyRecipient?.last_name}
              </div>
            </h4>

            <div className="hstack gap-2 justify-content-center">
              <Link to="#" className="btn btn-link link-success fw-medium" onClick={() => {}}></Link>
            </div>
          </div>

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
            }}
          >
            <ModalBody>
              <Input type="hidden" id="id-field" />
              <Row className="g-3">
                <div className="mb-4">
                  <Label className="form-label">Enter password to continue</Label>
                  <Input
                    name="password"
                    className="form-control"
                    placeholder="Enter password"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    invalid={validation.touched.password && validation.errors.password ? true : false}
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      <div>{validation.errors.password}</div>
                    </FormFeedback>
                  ) : null}
                </div>
              </Row>
            </ModalBody>
            <ModalFooter>
              {validation.isSubmitting ? (
                <Button disabled={validation.isSubmitting} color="info" className="btn-load">
                  <span className="d-flex align-items-center">
                    <span className="flex-grow-1 me-2">Loading...</span>
                    <Spinner size="sm" type="grow" className="flex-shrink-0" role="status">
                      {" "}
                      Loading...{" "}
                    </Spinner>
                  </span>
                </Button>
              ) : (
                <button type="submit" className="btn btn-primary btn-label right ms-auto nexttab">
                  <i className="ri-send-plane-line label-icon align-middle fs-16 ms-2"></i>
                  SEND
                </button>
              )}
            </ModalFooter>
            {errMsg ? <MsgToast msg={errMsg} color="danger" icon="ri-checkbox-circle-line" /> : null}
          </Form>
        </ModalBody>
      </Modal>
      {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}

      <ProccessingLoader alert={alert} setAlert={setAlert} />
    </React.Fragment>
  );
};

export default SendMoneyModal;
