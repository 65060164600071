import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

import { APIClient } from "../../../utils/api_helper";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import ProccessingLoader from "../../../Components/Loaders/ProccessingLoader";

import React, { useState } from "react";

import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";

import TextField from "../../../Components/Common/Fields/TextField";

const api = new APIClient();

const RepayLoanModal = ({ isModalOpen, toggleModal, modalItem, simplyValidation, openModal, setOpenModal }) => {
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrorMsg] = useState("");

  const [alert, setAlert] = useState({
    status: false,
    title: "Proccessing...",
    message: "Proccessing please wait...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-primary",
  });

  // Custom Vertical Tabs

  // Border Top Nav
  const [topBorderTab, settopBorderTab] = useState("1");
  const topBordertoggle = (tab) => {
    if (topBorderTab !== tab) {
      settopBorderTab(tab);
    }
  };

  // events validation
  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      //   phone: simplyValidation?.values.phone,
      //   amount: simplyValidation?.values.amount,
      type: "",
      paymentMethod: "",
      amount: "",
      billName: "",
      billNumber: "",
      serviceCode: "",
      serviceCode: modalItem?.serviceCode,
    },

    validationSchema: Yup.object({
      phone: Yup.string().required("Please enter your event name"),
      amount: Yup.string().required("Please select your meter number"),
      serviceCode: Yup.string().required("Please enter your service code"),
    }),

    onSubmit: async (values) => {
      try {
        setAlert({ ...alert, status: true });

        setErrorMsg("");
        setSuccessMsg("");
        console.log(values, "VALUESS__REQUESTTS");
        const data = await api.post("/transaction/transfer/simply/", values);
        console.log(data, "REQUEST__STATS__DATAA");

        // setRequestSuccess(data.message);

        setSuccessMsg(data.message);
        setErrorMsg("");

        simplyValidation.resetForm();

        toggleModal();

        setAlert({ ...alert, status: false });

        // setTimeout(() => {
        //  }, 2000);
      } catch (error) {
        setAlert({ ...alert, status: false });

        console.log(error, "LOGIN");
        setErrorMsg("");

        let errMsg = error?.response?.data?.message;

        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";

        setErrorMsg(errMsg);

        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  return (
    <React.Fragment>
      <Modal
        id="topmodal"
        isOpen={openModal}
        toggle={() => {
          // toggleModal();
        }}
      >
        <ModalHeader
          className="modal-title"
          id="myModalLabel"
          toggle={() => {
            validation.resetForm();
            setOpenModal(false);
          }}
        ></ModalHeader>
        <ModalBody className="text-center">
          <div className="">
            <h4 className="">
              {/* <img className="rounded-circle avatar-xl" src={modalItem.img} width="200" /> */}

              <div className="btn-outline-primary fw-bold p-1">Repay Loan</div>
            </h4>

            <div className="hstack gap-2 justify-content-center">
              <Link to="#" className="btn btn-link link-success fw-medium" onClick={() => {}}></Link>
            </div>
          </div>

          <Col lg={12} xl={12} xxl={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col xxl={12} lg={12} xl={12}>
                    <h5 className="mb-1">Loan Repayment</h5>

                    <CardBody>
                      <Nav tabs className="nav nav-tabs nav-justified nav-border-top nav-border-top-primary mb-3">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({ active: topBorderTab === "1" })}
                            onClick={() => {
                              topBordertoggle("1");
                            }}
                          >
                            <i className=" ri-router-line align-middle me-1"></i> ACCOUNT
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({ active: topBorderTab === "2" })}
                            onClick={() => {
                              // topBordertoggle("2");
                            }}
                          >
                            <i className="ri-apps-line me-1 align-middle"></i> DETAILS
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({ active: topBorderTab === "3" })}
                            onClick={() => {
                              // topBordertoggle("3");
                            }}
                          >
                            <i className=" ri-send-plane-fill align-middle me-1"></i>
                            PAYMENT
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent activeTab={topBorderTab} className="text-muted">
                        <TabPane tabId="1" id="nav-border-justified-home">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                            }}
                          >
                            <ModalBody>
                              {/* <Input type="hidden" id="id-field" /> */}
                              <Row className="g-3">
                                <TextField
                                  md="12"
                                  mb="mb-3"
                                  value={validation.values.billNumber || ""}
                                  onChange={validation.handleChange}
                                  validation={validation}
                                  onBlur={validation.handleBlur}
                                  label={"amount"}
                                  name="billNumber"
                                  placeholder="eg: 10090900000"
                                />
                              </Row>
                            </ModalBody>
                            <ModalFooter>
                              {validation.isSubmitting ? (
                                <Button disabled={validation.isSubmitting} color="info" className="btn-load">
                                  <span className="d-flex align-items-center">
                                    <span className="flex-grow-1 me-2">Loading...</span>
                                    <Spinner size="sm" type="grow" className="flex-shrink-0" role="status">
                                      {" "}
                                      Loading...{" "}
                                    </Spinner>
                                  </span>
                                </Button>
                              ) : (
                                <button type="submit" className="btn btn-success btn-label right ms-auto nexttab">
                                  <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>
                                  PROCEED
                                </button>
                              )}
                            </ModalFooter>
                            {errMsg ? <MsgToast msg={errMsg} color="danger" icon="ri-checkbox-circle-line" /> : null}
                          </Form>
                        </TabPane>

                        <TabPane tabId="2" id="nav-border-justified-profile"></TabPane>

                        <TabPane tabId="3" id="nav-border-justified-messages"></TabPane>
                      </TabContent>
                    </CardBody>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
      </Modal>
      {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}

      <ProccessingLoader alert={alert} setAlert={setAlert} />
    </React.Fragment>
  );
};

export default RepayLoanModal;
