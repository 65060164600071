import React, { useState } from "react";

//Import Breadcrumb
import Cleave from "cleave.js/react";
import AsyncSelect from "react-select/async";

import {
  Card,
  CardBody,
  CardHeader,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  Row,
  Label,
  Input,
  Button,
  Spinner,
  ModalFooter,
  Col,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import useSWR from "swr";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

import { APIClient } from "../../../utils/api_helper";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import SendMoneyModal from "../SendMoneyModal";
import ProccessingLoader from "../../../Components/Loaders/ProccessingLoader";
import { Link } from "react-router-dom";

import TransferReceivers from "../Modules/TransferReceivers";

const api = new APIClient();
const fetcher = async (url) => {
  const { data } = await api.get(url);

  return data;
};

const SimplyTab = ({ user }) => {
  document.title = "";

  const [errMsg, setErrorMsg] = useState("");

  let { data, error, isValidating, mutate } = useSWR(`/transaction/transfer/receivers/`, fetcher, {});

  const [isModalActive, setIsModalActive] = useState(false);
  const [recipient, setRecipient] = useState(false);

  const [alert, setAlert] = useState({
    status: false,
    title: "Checking user...",
    message: "Checking user, please wait...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-primary",
  });

  // validation
  const simplyValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      recipient: "",
      amount: "",
    },

    validationSchema: Yup.object({
      recipient: Yup.string().required("Please enter a reciepient"),
      amount: Yup.string().required("Please enter a amount"),
    }),

    onSubmit: async (values) => {
      console.log("sending_money_SIMPLY", values);

      setErrorMsg("");
      // setRequestSuccess("");
      // setSimplyRecipient({});

      setAlert({
        status: true,
        title: "Checking user...",
        message: "Checking user, please wait...",
        class: "spinner-border spinner-border-lg avatar-sm",
        textClass: "text-primary",
      });
      try {
        const result = values.amount.replace(/[^0-9.]/g, "");

        values.amount = result;

        const { data } = await api.get("/users/user/confirm/", values);

        console.log(data, "USERRR_OBJECTS");

        // setRequestSuccess(data.message);
        // setSimplyRecipient(data);
        setAlert({ ...alert, status: false });

        setIsModalActive(true);
      } catch (error) {
        // setAlert({ ...alert, status: false });

        console.log(error, "FUND_MOMO_ERROR");

        let errMsg = error?.response?.data?.message;

        errMsg = errMsg || "internal server occured";

        setErrorMsg(errMsg);

        setAlert({
          status: true,
          title: "Sorry!",
          message: errMsg,
          //class: "ri-information-line",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-primary",
          //back_url: "/accounts/plans",
          //// redirect user to the customer portal
          dismissable: true,
        });

        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  const loadUsersOptions = (inputValue) => {
    return new Promise((resolve) => {
      console.log(inputValue, "INNPUT__vALUE");
      let users = fethUsers(inputValue);
      // const orgList = fethUsers({ search: inputValue }).then(({ contact_obj_list }) => contact_obj_list);
      resolve(users);
    });
  };

  const fethUsers = async (values) => {
    try {
      const { data } = await api.get("/users/search/", { params: { value: values } });

      console.log(data, "USERRR_OBJECTS");
      return data;
    } catch (error) {
      // setAlert({ ...alert, status: false });

      console.log(error, "FUND_MOMO_ERROR");

      let errMsg = error?.response?.data?.message;
    }
  };

  console.log(simplyValidation.values, "S__VALUESS");
  console.log(recipient, "REDEIIPIENT");

  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          simplyValidation.handleSubmit();
          return false;
        }}
      >
        <div className="collapse show" id="paymentmethodCollapse">
          {/* <Card className="p-4 border shadow-none mb-0 mt-4"> */}
          <Row className="">
            <Col md={6}>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="choices-lead-input" className="form-label">
                    Search for recipient by First name, Last name, Full name , or Email
                  </Label>

                  <AsyncSelect
                    className={`${
                      simplyValidation.touched.recipient && simplyValidation.errors.recipient
                        ? "form-control is-invalid"
                        : "form-control"
                    } `}
                    id="choices-lead-input"
                    value={recipient}
                    key={recipient}
                    name="user_id"
                    isMulti={false}
                    isSearchable
                    isClearable
                    loadOptions={loadUsersOptions}
                    getOptionLabel={(option) => (
                      <div>
                        <Link to="#" className="d-flex align-items-center">
                          <div className="">
                            <img height="50" src={option?.face_id} alt="" className="avatar-md rounded-circle" />
                          </div>
                          <div className="">
                            <h6 className="fs-14">
                              {option?.first_name} {option?.last_name}
                            </h6>
                          </div>
                        </Link>
                      </div>
                    )}
                    getOptionValue={(option) => option?.uuid}
                    defaultOptions={[]}
                    onBlur={simplyValidation.handleBlur}
                    onChange={(e) => {
                      setRecipient(e);
                      simplyValidation.setFieldValue("recipient", e?.uuid);
                    }}
                  />
                  {simplyValidation.touched.donor && simplyValidation.errors.donor ? (
                    <FormFeedback type="invalid">{simplyValidation.errors.donor}</FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col md={12}>
                <Label htmlFor="cc-name" className="form-label">
                  Enter the amount to send
                </Label>
                <div className="input-group mb-3">
                  <span className="input-group-text">{user?.currency_symbol}</span>

                  <Cleave
                    className="form-control bg-light"
                    aria-label="Dollar amount (with dot and two decimal places)"
                    placeholder="0.00"
                    minLength={10}
                    maxLength={15}
                    onChange={simplyValidation.handleChange}
                    onBlur={simplyValidation.handleBlur}
                    value={simplyValidation.values.amount || ""}
                    invalid={simplyValidation.touched.amount && simplyValidation.errors.amount ? true : false}
                    options={{
                      numeral: true,
                      numeralThousandsGroupStyle: "thousand",
                    }}
                    name="amount"
                  />

                  {simplyValidation.touched.amount && simplyValidation.errors.amount ? (
                    <FormFeedback type="invalid">
                      <div>{simplyValidation.errors.amount}</div>
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <div className="hstack gap-2 justify-content-end mt-3">
                {simplyValidation.isSubmitting ? (
                  <Button disabled={simplyValidation.isSubmitting} color="btn btn-primary" className="btn-load">
                    <span className="d-flex align-items-center">
                      <span className="flex-grow-1 ">Loading...</span>
                      <Spinner size="sm" type="grow" className="flex-shrink-0" role="status">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                    </span>
                  </Button>
                ) : (
                  <button type="submit" className="btn btn-success btn-label right ms-auto nexttab">
                    <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>
                    Proceed
                  </button>
                )}
              </div>
            </Col>

            <TransferReceivers mutate={mutate} setRecipient={setRecipient} validation={simplyValidation} data={data} />
          </Row>

          <div className="text-muted mt-2 fst-italic">
            <i data-feather="lock" className="text-muted icon-xs"></i> Your transaction is secured with SSL encryption
          </div>
        </div>

        {errMsg ? <MsgToast msg={errMsg} color="danger" icon="ri-checkbox-circle-line" /> : null}
      </Form>

      <SendMoneyModal
        isModalOpen={isModalActive}
        simplyRecipient={recipient}
        simplyValidation={simplyValidation}
        toggleModal={() => setIsModalActive(!isModalActive)}
        mutate={mutate}
      />

      <ProccessingLoader alert={alert} setAlert={setAlert} />
    </React.Fragment>
  );
};

export default SimplyTab;
