import React from "react";
import { CardBody, Row, Col, Card, Container } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";

import useSWR from "swr";
import { useSelector } from "react-redux";

import { APIClient } from "../../utils/api_helper";

import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import StatementSkeleton from "../../Components/Skeletons/StatementSkeleton";
import { Status } from "./TransactionsCol";

const api = new APIClient();

const fetchRequest = async (url) => {
  const data = await api.get(url);

  return data;
};

const TransactionDetails = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const { id } = useParams();

  // Access a specific query parameter
  // const id = query.get("id");

  let { data, error, isValidating } = useSWR(`/transaction/charge/simply/${id}/`, fetchRequest, {
    revalidateOnFocus: false, // revalidate when the page gains focus
    revalidateOnReconnect: false, // revalidate when the browser regains network connectivity
  });

  let request = data?.data;

  //Print the Invoice
  const printTransaction = () => {
    window.print();
  };

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));
  const currentUserId = user?.uuid;

  console.log(user, "USERRR");

  const is_user_requester = (request) => {
    return request?.requester?.uuid === currentUserId;
  };

  const isCurrentUserRequester = is_user_requester(request);

  console.log(request, isCurrentUserRequester, "transactionVVVV");

  console.log(isCurrentUserRequester, "isCurrentUserRequester");

  document.title = "Invoice Details";

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="payment request detail" linkTo={"/requests"} pageTitle="payment requests" />

        <StatementSkeleton>
          {request && (
            // <Row className="justify-content-center">
            <Col xxl={9}>
              <Card id="demo">
                <Row>
                  <Col lg={12}>
                    <CardBody className="p-4">
                      <Row className="g-3">
                        <Col lg={3} xs={6}>
                          <p className="text-muted mb-2 text-uppercase fw-semibold">Request Status</p>

                          <Status status={request?.status} />

                          {/* <span className="badge badge-soft-success fs-11" id="payment-status">
                              {request?.status}
                            </span> */}
                        </Col>
                        <Col lg={3} xs={6}>
                          <p className="text-muted mb-2 text-uppercase fw-semibold">request Ref</p>
                          <h5 className="fs-14 mb-0">
                            <span id="request-no">{request?.reference}</span>
                          </h5>
                        </Col>
                        <Col lg={3} xs={6}>
                          <p className="text-muted mb-2 text-uppercase fw-semibold">Date</p>
                          <h5 className="fs-14 mb-0">
                            <span id="request-date"> {request?.created_at.split("T")[0]}</span>
                            {", "}
                            <small className="text-muted" id="request-time">
                              {request?.created_at?.split("T")[1]?.split(".")[0]}
                            </small>
                          </h5>
                        </Col>

                        <Col lg={3} xs={6}>
                          <p className="text-muted mb-2 text-uppercase fw-semibold">Requested Amount</p>

                          <h5
                            className={`text amount mb-1  ${
                              isCurrentUserRequester ? "btn-ghost-success" : "btn-ghost-danger"
                            }`}
                          >
                            {isCurrentUserRequester ? "+" : "-"}
                            {user?.currency_symbol}
                            {request?.amount}
                          </h5>
                        </Col>
                      </Row>
                    </CardBody>
                  </Col>

                  <Row>
                    <Col lg={6}>
                      <CardBody className="p-4">
                        <div className="mt-3">
                          <h6 className="text-muted text-uppercase fw-semibold mb-3">Details:</h6>
                          {/* <p className="text-muted mb-1">
                              Payment Method:{" "}
                              <span className="fw-medium" id="payment-method">
                                {request?.payment_method}
                              </span>
                            </p> */}

                          {request?.full_name ? (
                            <p className="text-muted mb-1">
                              Account Holder:{" "}
                              <span className="fw-medium" id="card-holder-name">
                                {request?.full_name}
                              </span>
                            </p>
                          ) : null}

                          {request?.account_number ? (
                            <p className="text-muted mb-1">
                              Account Number:{" "}
                              <span className="fw-medium" id="card-number">
                                {request?.account_number}
                              </span>
                            </p>
                          ) : null}

                          <p className="text-muted">
                            Amount:{" "}
                            <span className="fw-medium" id="">
                              {user?.currency_symbol} {request?.amount}
                            </span>
                          </p>

                          <p className="text-muted">
                            Time:{" "}
                            <span className="fw-medium" id="">
                              {request?.created_at_arrow}
                            </span>
                          </p>
                        </div>
                      </CardBody>
                    </Col>

                    {isCurrentUserRequester ? (
                      <Col lg={6}>
                        <CardBody>
                          <img src={request?.requester_cam} alt="" className="rounded-circle avatar-xl" />

                          <p className="text-muted">
                            Request to:{" "}
                            <span className="fw-bold fs-20" id="">
                              {request?.requestee?.first_name} {request?.requestee?.last_name}
                            </span>
                          </p>
                        </CardBody>
                      </Col>
                    ) : (
                      <Col lg={6}>
                        <CardBody>
                          <img src={request?.requester_cam} alt="" className="rounded-circle avatar-xl" />

                          <p className="text-muted">
                            Request from:{" "}
                            <span className="fw-bold fs-20" id="">
                              {request?.requester?.first_name} {request?.requester?.last_name}
                            </span>
                          </p>
                        </CardBody>
                      </Col>
                    )}
                  </Row>

                  <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                    <Link to="#" onClick={printTransaction} className="btn btn-success">
                      <i className="ri-download-2-line align-bottom me-1"></i> Download
                    </Link>
                    {/* <Link to="#" onClick={printTransaction} className="btn btn-primary">
                      <i className="ri-download-2-line align-bottom me-1"></i> Download
                    </Link> */}
                  </div>
                </Row>
              </Card>
            </Col>
            // </Row>
          )}
        </StatementSkeleton>
      </Container>
    </div>
  );
};

export default TransactionDetails;
{
  /* <img src={avatar2} alt="" className="rounded avatar-xxs" />

<img src={avatar10} alt="" className="rounded avatar-xs" />

<img src={avatar3} alt="" className="rounded avatar-sm" />

<img src={avatar4} alt="" className="rounded avatar-md" />

<img src={avatar5} alt="" className="rounded avatar-lg" />

<img src={avatar8} alt="" className="rounded avatar-xl" />

<img src={avatar2} alt="" className="rounded-circle avatar-xxs" />

<img src={avatar10} alt="" className="rounded-circle avatar-xs" />

<img src={avatar3} alt="" className="rounded-circle avatar-sm" />

<img src={avatar4} alt="" className="rounded-circle avatar-md" />

<img src={avatar5} alt="" className="rounded-circle avatar-lg" />

<img src={avatar8} alt="" className="rounded-circle avatar-xl" /> */
}
