import React from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import CountUp from "react-countup";
import useSWR from "swr";

import { APIClient } from "../../utils/api_helper";
import StepLineChart from "../../Components/Charts/StepLineChart";
import ListLoader from "../../Components/modules/ListLoader";
import SettingsSkeleton from "../../Components/Skeletons/SettingsSkeleton";
import { useNavigate, useSearchParams } from "react-router-dom";
import RefreshButton from "../../Components/Loaders/RefreshButton";
import { useSelector } from "react-redux";

const api = new APIClient();

const transactionListFetcher = async (url) => {
  console.log(url, "STATEEE_URRL_LOADING");

  const data = await api.get(url);

  return data;
};

const countDaysOptions = [
  {
    label: "1 Week",
    count_days: "7",
  },
  {
    label: "1 Month",
    count_days: "30",
  },
  {
    label: "2 Months",
    count_days: "90",
  },
  {
    label: "3 Months",
    count_days: "120",
  },
];

const Statements = ({ dataColors }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(searchParams);
  const navigate = useNavigate();
  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));

  // Access a specific query parameter
  const count_days = queryParams.get("count_days") || "90";

  let { data, error, isValidating, mutate } = useSWR(
    `/transaction/statistics/statements/?count_days=${count_days}`,
    transactionListFetcher,
    {
      revalidateOnFocus: false, // revalidate when the page gains focus
      revalidateOnReconnect: false, // revalidate when the browser regains network connectivity
    }
  );

  const setQuery = (value) => {
    // const searchParams = new URLSearchParams(location.search);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("count_days", value);

    // Replace the current URL with the updated query parameters
    navigate({ search: newSearchParams.toString() });
  };

  console.log(data, "STATEMENTS");

  document.title = "Statements | SimplyPay";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Statement" pageTitle="Payment requests" linkTo="/requests" />
          <Row>{/* <Widgets data={stats} /> */}</Row>

          <SettingsSkeleton>
            {data ? (
              <Card>
                <CardHeader>
                  <Row>
                    <Col md={6}>
                      <div className="flex-grow-1">
                        <h5 className="card-title mb-0">My Statements</h5>
                      </div>
                    </Col>

                    <Col md={6} className="d-flex justify-content-end gap-1">
                      <RefreshButton isValidating={isValidating} mutate={mutate} />

                      {countDaysOptions &&
                        countDaysOptions.map((count, i) => {
                          const buttonClassName =
                            count.count_days === count_days
                              ? `btn btn-primary timeline-btn btn-sm`
                              : `btn btn-soft-primary timeline-btn btn-sm`;

                          return (
                            <button
                              key={i}
                              onClick={() => setQuery(count.count_days)}
                              type="button"
                              className={buttonClassName}
                              id="one_month"
                            >
                              {count.label}
                            </button>
                          );
                        })}

                      {/* <button
                    onClick={() => setQuery("7")}
                    type="button"
                    className="btn btn-primary timeline-btn btn-sm"
                    id="one_month"
                  >
                    1 week
                  </button>
                  <button
                    onClick={() => setQuery("30")}
                    type="button"
                    className="btn btn-soft-primary timeline-btn btn-sm"
                    id="one_month"
                  >
                    1 Month
                  </button>
                  <button
                    onClick={() => setQuery("90")}
                    type="button"
                    className="btn btn-soft-primary timeline-btn btn-sm"
                    id="six_months"
                  >
                    2 Months
                  </button>
                  <button
                    onClick={() => setQuery("120")}
                    type="button"
                    className="btn btn-soft-primary timeline-btn btn-sm"
                    id="one_year"
                  >
                    3 months
                  </button> */}
                    </Col>
                  </Row>
                </CardHeader>

                <CardHeader className="p-0 border-0 bg-soft-light">
                  <Row className="g-0 text-center">
                    <Col xs={16} sm={4}>
                      <div className="p-3 border border-dashed border-start-0">
                        <h5 className="mb-1">
                          <h3 className="counter-value  btn-ghost-success" data-target="854">
                            + {user?.currency_symbol}{" "}
                            <CountUp start={0} end={data?.totals?.total_payins} duration={2} decimals={2} />
                            {/* <span className="text-success ms-1 fs-12">
                              49%<i className="ri-arrow-right-up-line ms-1 align-middle"></i>
                            </span> */}
                          </h3>
                        </h5>
                        <p className="text-muted mb-0">Total Payins</p>
                      </div>
                    </Col>
                    <Col xs={6} sm={4}>
                      <div className="p-3 border border-dashed border-start-0">
                        <h5 className="mb-1">
                          <h3 className="counter-value  btn-ghost-danger" data-target="854">
                            - {user?.currency_symbol}{" "}
                            <CountUp start={0} end={data?.totals?.total_payout} duration={2} decimals={2} />
                            <span className="text-success ms-1 fs-12">
                              4%<i className="ri-arrow-right-up-line ms-1 align-middle"></i>
                            </span>
                          </h3>
                        </h5>
                        <p className="text-muted mb-0">Total Payouts</p>
                      </div>
                    </Col>
                    <Col xs={6} sm={4}>
                      <div className="p-3 border border-dashed border-start-0">
                        <h5 className="mb-1">
                          <h3 className="counter-value  btn-ghost-success" data-target="854">
                            + {user?.currency_symbol}{" "}
                            <CountUp start={0} end={data?.totals?.total_requests} duration={2} decimals={2} />
                            {/* <span className="text-success ms-1 fs-12">
                              9%<i className="ri-arrow-right-up-line ms-1 align-middle"></i>
                            </span> */}
                          </h3>
                        </h5>
                        <p className="text-muted mb-0">Total requests Payins</p>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                {isValidating ? <ListLoader /> : null}
                <CardBody>
                  <div>
                    <div className="apex-charts" dir="ltr">
                      <StepLineChart
                        stats={data?.stats}
                        dataColors='["--vz-primary", "--vz-success", "--vz-warning"]'
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            ) : null}
          </SettingsSkeleton>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Statements;
