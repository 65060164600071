import React, { useEffect, useState } from "react";

//Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Cleave from "cleave.js/react";

import {
  Container,
  Form,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalFooter,
  Alert,
  Spinner,
  Button,
  CardHeader,
  Input,
  Label,
} from "reactstrap";
import AddCardModal from "./Modals/AddCardModal";
import useSWR from "swr";

import SkeletonSmall from "../../../Components/Skeletons/SkeletonSmall";
import SelectField from "../../../Components/Common/Fields/SelectField";
import { useDispatch, useSelector } from "react-redux";

import MsgToast from "../../../Components/Common/MsgToast";
import ProccessingLoader from "../../../Components/Loaders/ProccessingLoader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { APIClient } from "../../../utils/api_helper";
import WalletCards from "./Cards/WalletCards";
import { getCards } from "../../../store/actions";
import OtpModal from "./Modals/OtpModal";

const api = new APIClient();

const getUserLoan = async (url) => {
  const { data } = await api.get(url);
  return data;
};

const DepositMoney = () => {
  const dispatch = useDispatch();
  const [openAddCardModal, setOpenAddCardModal] = useState(false);

  const [defaultCard, setCardDefault] = useState({});

  const [walletOptions, setWalletOptions] = useState({});

  const [openOtpModal, setOpenOtpModal] = useState(false);

  const [reference, setReference] = useState("");

  const [isCheckingTransactionStatus, setIsCheckingTransactionStatus] = useState(false);

  const [transactionRef, setTransactionRef] = useState("");

  const [isFullPayment, setIsFullPayment] = useState(true);
  let { data } = useSWR(`/loan/info/`, getUserLoan, {});

  const { cards, isFetchingCards, user } = useSelector((state) => ({
    cards: state.Profile.cards,
    isFetchingCards: state.Profile.isFetchingCards,
    user: state.Profile.user,
  }));

  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrorMsg] = useState("");

  const [alert, setAlert] = useState({});

  useEffect(() => {
    if (!cards) {
      dispatch(getCards({ is_loan_repayment: true }));
    }
  }, []);

  useEffect(() => {
    const defaultCard = getDefaultCardOptions(cards);

    const walletOptions = getSelectOptions(cards);

    setWalletOptions(walletOptions);

    setCardDefault(defaultCard);
  }, [cards]);

  console.log(cards, "CARDDS");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      amount: isFullPayment ? data?.userLoanBalance : "",
      reference: defaultCard?.value,
    },

    validationSchema: Yup.object({
      amount: Yup.string().required("Please select the amount you want to depositW"),
      reference: Yup.string().required("Please enter a reference"),
    }),

    onSubmit: async (values) => {
      try {
        setErrorMsg("");
        setSuccessMsg("");
        console.log(values, "DEPOSITING");
        setAlert({
          status: true,
          title: "Proccessing charge...",
          message: "Proccessing, please wait...",
          class: "spinner-border spinner-border-lg",
          textClass: "text-primary",
        });
        console.log(values, "VALUESS__REQUESTTS");

        values["amount"] = +values["amount"];

        const data = await api.post("/loan/repayment/", values);
        console.log(data, "DEPOSITING");

        const status = data.charge_response.status;

        if (status === "send_otp") {
          setOpenOtpModal(true);
          setAlert({
            status: false,
          });
        } else if (status === "pay_offline") {
          setTransactionRef(data.charge_response.reference);

          setAlert({
            status: true,
            title: "INPUT PIN...",
            message: data.charge_response.display_text,
            // class: "spinner-border spinner-border-lg",
            back_url: "/deposit-money",
            goto_url: "/loan/list",
            goto_text: "View loan list",

            icon: (
              <lord-icon
                src="https://cdn.lordicon.com/hbtheitu.json"
                style={{ width: "150px", height: "150px" }}
                trigger="loop"
                colors="primary:#f7b84b,secondary:#405189"
              ></lord-icon>
            ),
          });
          setIsCheckingTransactionStatus(true);
        } else if (status === "success") {
          setAlert({
            status: true,
            title: (
              <span className={""}>
                <i className={`text-success bx`}>LOAN PAID SUCCESSFULLY</i>
              </span>
            ),
            message: data.message,
            // class: "spinner-border spinner-border-lg",
            back_url: "/loan",
            // goto_url: `/loan/list`,
            icon: (
              <lord-icon
                src="https://cdn.lordicon.com/tqywkdcz.json"
                style={{ width: "150px", height: "150px" }}
                trigger="loop"
                colors="primary:#f7b84b,secondary:#405189"
              ></lord-icon>
            ),
          });
        }

        setSuccessMsg(data.charge_response.display_text);

        if (status !== "success") {
          setReference(data.charge_response.reference);
        }

        // setAlert({ ...alert, status: false });
      } catch (error) {
        console.log(error, "LOGIN");
        setErrorMsg("");
        let errMsg = error?.response?.data?.message;
        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";
        setErrorMsg(errMsg);
        console.log(errMsg, "ER__MERSG");
        setAlert({
          status: true,
          title: "Sorry!",
          message: errMsg,
          //class: "ri-information-line",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-primary",
          //back_url: "/accounts/plans",
          dismissable: true,
        });
      }
    },
  });

  const getSelectOptions = (dataArray) => {
    if (!dataArray) return;

    let options = dataArray.map((arr) => {
      return { value: arr.reference, label: `${arr.account_number} ${arr.type.toUpperCase()}` };
    });

    return options;
  };

  function getDefaultCardOptions(cards) {
    if (!cards) return;

    for (let i = 0; i < cards.length; i++) {
      if (cards[i].default) {
        // Break out of the loop when the condition is met
        return { value: cards[i].reference, label: `${cards[i].account_number} ${cards[i].type.toUpperCase()}` };
        break;
      }
    }
  }

  console.log(defaultCard, "DEFAULT__CARD");

  console.log(validation.errors, "CARD__OPTIONS");
  console.log(validation.values, "VALIDATION__VALUES");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="LOAN REPAYMENT" />

          <Row>
            <TabPane tabId={1} id="pills-bill-info">
              <CardHeader>
                <Row>
                  <Col>
                    <div>
                      <h5 className="mb-1">
                        Your current outstanding loan balance is{" "}
                        <Button color="danger" className="btn-sm w-10">
                          <i className="ri-question-answer-line align-bottom me-1"></i>
                          {user?.currency_symbol} {data?.userLoanBalance}
                        </Button>{" "}
                      </h5>
                      <p className="text-muted mb-4">Late loan payment affect your credit score</p>
                    </div>
                  </Col>

                  <Col md={3} className="d-flex justify-content-end">
                    <button
                      onClick={() => setOpenAddCardModal(!openAddCardModal)}
                      type="button"
                      className="btn btn-soft-success"
                    >
                      <i className="ri-add-circle-line align-middle me-1"></i> Add wallet
                    </button>
                  </Col>
                </Row>
              </CardHeader>

              <Card className="p-4 border shadow-none mb-0 mb-4 ">
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title"> {isFullPayment ? "Full payment" : "Part Payment"}</h4>
                  <div className="flex-shrink-0">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <Label className="form-label text-muted"></Label>
                      <Input
                        className="form-check-input code-switcher"
                        type="checkbox"
                        value={isFullPayment}
                        name="isTermsAccepted"
                        role="switch"
                        id="isTermsAccepted"
                        checked={isFullPayment}
                        onChange={(e) => {
                          console.log(e.target.checked, "CHECCKKEDDD");
                          setIsFullPayment(e.target.checked);
                        }}
                      />
                    </div>
                  </div>
                </CardHeader>

                {/* <Col>
                  <h5 className="">Available wallet</h5>
                </Col> */}

                {isFetchingCards ? (
                  <p>
                    <SkeletonSmall></SkeletonSmall>
                  </p>
                ) : (
                  ""
                )}

                {!isFetchingCards && !cards?.length ? (
                  <Alert color="warning">
                    <strong> ! </strong>
                    You dont have a wallet yet, <b> Add a wallet </b>to make a deposit
                  </Alert>
                ) : (
                  ""
                )}

                {/* <WalletCards cards={cards} cardOptions={cardOptions} /> */}

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                  }}
                >
                  <Row className="gy-2">
                    <SelectField
                      md="6"
                      mb="mb-3"
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="select..."
                      label="Select wallet"
                      name="reference"
                      defaultValue={defaultCard}
                      // defaultValue={walletOptions?.length && walletOptions?.find((c) => c.value === defaultCard?.value)}
                      options={walletOptions}
                    />

                    <Col xl={6} md={6}>
                      <label htmlFor="cleave-numeral" className="form-label">
                        Amount
                      </label>

                      <div className="input-group mb-3">
                        <span className="input-group-text">{user?.currency_symbol}</span>

                        <Cleave
                          placeholder="Enter amount to pay"
                          value={validation.values.amount}
                          onChange={validation.handleChange}
                          defaultValue={defaultCard}
                          label="Select wallet"
                          name="amount"
                          options={{
                            numeral: true,
                            numeralThousandsGroupStyle: "thousand",
                          }}
                          className="form-control"
                        />
                      </div>
                    </Col>
                  </Row>
                  <ModalFooter>
                    {validation.isSubmitting ? (
                      <Button disabled={validation.isSubmitting} color="info" className="btn-load">
                        <span className="d-flex align-items-center">
                          <span className="flex-grow-1 me-2">Loading...</span>
                          <Spinner size="sm" type="grow" className="flex-shrink-0" role="status">
                            {" "}
                            Loading...{" "}
                          </Spinner>
                        </span>
                      </Button>
                    ) : (
                      <button type="submit" className="btn btn-primary btn-label right ms-auto nexttab">
                        <i className="ri-send-plane-line label-icon align-middle fs-16 ms-2"></i>
                        PAY LOAN
                      </button>
                    )}
                  </ModalFooter>
                </Form>
              </Card>

              {/* <div className="d-flex align-items-start gap-3 mt-4">
                <button
                  type="button"
                  className="btn btn-success btn-label right ms-auto nexttab"
                  onClick={() => {
                    console.log("CONFIRMING SEND");
                  }}
                >
                  <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>
                  Proceed
                </button>
              </div> */}
            </TabPane>
          </Row>
        </Container>
      </div>

      {errMsg ? <MsgToast msg={errMsg} color="danger" icon="ri-checkbox-circle-line" /> : null}

      {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}

      <AddCardModal openAddCardModal={openAddCardModal} setOpenAddCardModal={setOpenAddCardModal} />

      <OtpModal openOtpModal={openOtpModal} setOpenOtpModal={setOpenOtpModal} reference={reference} />

      <ProccessingLoader alert={alert} setAlert={setAlert} />
    </React.Fragment>
  );
};

export default DepositMoney;
