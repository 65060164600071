import React, { useState, Fragment } from "react";
import { CountrySelect, StateSelect } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { Col, Row } from "reactstrap";

export default function CountrySelectField({ validation }) {
  const [country, setCountry] = useState(null);
  const [currentState, setCurrentState] = useState(null);

  console.log(country, "COUNTRYYYY");
  return (
    <Fragment>
      <Col lg={6} className={"mb-3"}>
        <div>
          <h6>Country</h6>
          <CountrySelect
            containerClassName="form-group"
            inputClassName=""
            onChange={(_country) => {
              setCountry(_country);

              validation.setFieldValue("country", _country?.name);
              validation.setFieldValue("currency_symbol", _country?.currency_symbol);
              validation.setFieldValue("currency_name", _country?.currency_name);
              validation.setFieldValue("currency", _country?.currency);
            }}
            // onTextChange={(_txt) => console.log(_txt)}
            placeHolder="Select Country"
          />
        </div>
      </Col>
      <Col lg={6}>
        <div>
          <h6>State</h6>
          <StateSelect
            countryid={country?.id}
            containerClassName="form-group"
            inputClassName=""
            onChange={(_state) => {
              setCurrentState(_state);
              validation.setFieldValue("region", _state?.name);
            }}
            onTextChange={(_txt) => console.log(_txt)}
            defaultValue={currentState}
            placeHolder="Select State"
          />
        </div>
      </Col>
    </Fragment>
  );
}
