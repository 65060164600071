import React, { useState } from "react";

//Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";

import {
  Alert,
  Container,
  Form,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Accordion,
  AccordionItem,
  Button,
  Collapse,
  FormFeedback,
} from "reactstrap";
import useSWR from "swr";

import classnames from "classnames";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";

import { APIClient } from "../../../utils/api_helper";
import MsgToast from "../../../Components/Common/MsgToast";
import TextField from "../../../Components/Common/Fields/TextField";
import ProccessingLoader from "../../../Components/Loaders/ProccessingLoader";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
// import { BorderedAccordionExample } from "./UiAccordion&CollapseCode";

import { useParams } from "react-router-dom";

const api = new APIClient();

const getUserLoan = async (url) => {
  const { data } = await api.get(url);
  return data;
};

const CreateLoan = () => {
  const [modal, setModal] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);
  const [repayMentAmount, setRepaymentAmount] = useState("");
  const [loanInterestRate, setInterestRate] = useState("");
  const [interestOverdueRate, setInterestOverdueRate] = useState("");

  let { data } = useSWR(`/loan/info/`, getUserLoan, {});

  const { type } = useParams();

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));
  const toggledeletemodal = () => {
    setDeleteModal(!deletemodal);
  };

  const togglemodal = () => {
    setModal(!modal);
  };

  // Border Top Nav
  const [topBorderTab, settopBorderTab] = useState("1");
  const topBordertoggle = (tab) => {
    if (topBorderTab !== tab) {
      settopBorderTab(tab);
    }
  };

  const [alert, setAlert] = useState({
    status: false,
    title: "Proccessing fee...",
    message: "Proccessing please wait...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-success",
  });

  const [activeVerticalTab, setactiveVerticalTab] = useState(7);
  const [passedverticalSteps, setPassedverticalSteps] = useState([1]);

  const [errMsg, setErrorMsg] = useState("");

  const [successMsg, setSuccessMsg] = useState(false);

  function toggleVerticalTab(tab) {
    if (activeVerticalTab !== tab) {
      var modifiedSteps = [...passedverticalSteps, tab];

      if (tab >= 7 && tab <= 11) {
        setactiveVerticalTab(tab);
        setPassedverticalSteps(modifiedSteps);
      }
    }
  }

  const loanValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      amount: "",
      loan_type: type,
    },

    validationSchema: Yup.object({
      amount: Yup.string().required("Please enter the amount to be paid"),
    }),

    onSubmit: async (values) => {
      console.log(values, "CREATING LOAAAAAAAAAAAAN");
      setAlert({
        status: true,
        title: "Verifying loan status...",
        message: "Verifying loan status, please wait...",
        class: "spinner-border spinner-border-lg",
        textClass: "text-success",
      });

      setErrorMsg("");
      try {
        const data = await api.post("/loan/verify/", values);

        setInterestRate(data.interest_rate);
        setRepaymentAmount(data.total_repayment_amount);
        setInterestOverdueRate(data.overdue_rate);

        console.log(data, "FEESS_DSATA");
        toggleVerticalTab(activeVerticalTab + 1);

        setAlert({ ...alert, status: false });

        // setTimeout(() => {
        //   history.push("/");
        // }, 2000);
      } catch (error) {
        console.log(error, "LOGIN");
        let errMsg = error?.response?.data?.message;
        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";
        setErrorMsg(errMsg);

        setAlert({
          status: true,
          title: "Sorry!",
          message: errMsg,
          //class: "ri-information-line",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-primary",
          //back_url: "/accounts/plans",
          //// redirect user to the customer portal
          dismissable: true,
        });
        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      amount: loanValidation.values.amount,
      type,
      isTermsAccepted: false,
    },

    validationSchema: Yup.object({
      isTermsAccepted: Yup.bool().oneOf([true], "Accept terms & conditions"),
      amount: Yup.string().required("Please enter the loan amount"),
    }),

    onSubmit: async (values) => {
      console.log(values, "CREATING LOAAAAAAAAAAAAN");
      setAlert({
        status: true,
        title: "Submitting...",
        message: "Submiting loan request please wait...",
        class: "spinner-border spinner-border-lg",
        textClass: "text-success",
      });

      setErrorMsg("");
      try {
        const data = await api.post("/loan/", values);

        console.log(data, "FEESS_DSATA");

        setSuccessMsg(data.message);
        setAlert({ ...alert, status: false });

        setTimeout(() => {
          window.location.href = "/loan";
        }, 2000);
      } catch (error) {
        console.log(error, "LOGIN");
        let errMsg = error?.response?.data?.message;
        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";
        setErrorMsg(errMsg);

        setAlert({
          status: true,
          title: "Sorry!",
          message: errMsg,
          //class: "ri-information-line",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-primary",
          //back_url: "/accounts/plans",
          //// redirect user to the customer portal
          dismissable: true,
        });
        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  console.log(validation.values, "VALLL_VALUEEESS");
  console.log(validation.errors, "VALLL_ERRORRRSS");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="APPLY FOR LOAN" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">APPLY FOR {type?.toUpperCase()} LOAN</h4>
                </CardHeader>
                <CardBody className="form-steps">
                  <div className="vertical-navs-step">
                    <Row className="gy-5">
                      <Col lg={3}>
                        <Nav className="flex-column custom-nav nav-pills">
                          <NavItem>
                            <NavLink
                              href="#"
                              className={classnames({
                                active: activeVerticalTab === 7,
                                done: activeVerticalTab <= 11 && activeVerticalTab > 7,
                              })}
                              onClick={() => {
                                toggleVerticalTab(7);
                              }}
                            >
                              <span className="step-title me-2">
                                <i className="ri-close-circle-fill step-icon me-2"></i>
                                Step 1
                              </span>
                              Amount
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              href="#"
                              className={classnames({
                                active: activeVerticalTab === 8,
                                done: activeVerticalTab <= 11 && activeVerticalTab >= 8,
                              })}
                              onClick={() => {
                                // toggleVerticalTab(8);
                              }}
                            >
                              <span className="step-title me-2">
                                <i className="ri-close-circle-fill step-icon me-2"></i>
                                Step 2
                              </span>
                              Terms & conditions
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>

                      <Col lg={6}>
                        <div className="px-lg-4">
                          <TabContent activeTab={activeVerticalTab}>
                            <TabPane tabId={7}>
                              <div>
                                <h5>Loan information</h5>
                                <p className="text-muted">
                                  {data?.max_loan_amount > 0 ? (
                                    <p>
                                      Apply for a loan <b className="">{data?.loan_range}</b>
                                    </p>
                                  ) : (
                                    "You are not eligible for a loan at this moment"
                                  )}
                                </p>
                              </div>

                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  loanValidation.handleSubmit();
                                  return false;
                                }}
                              >
                                <div>
                                  <Row className="g-3">
                                    <Col sm={12}>
                                      <Label>Enter amount to loan</Label>
                                      <div className="input-group mb-3 barge barge-soft-primary">
                                        <span className="input-group-text mx-1">{user?.currency_symbol}</span>
                                        <Input
                                          type="text"
                                          className="form-control rounded-pill"
                                          aria-label="Dollar amount (with dot and two decimal places)"
                                          onChange={loanValidation.handleChange}
                                          value={loanValidation.values.amount}
                                          name={"amount"}
                                          placeholder="0.00"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="d-flex align-items-start gap-3 mt-4">
                                  <button
                                    type="submit"
                                    className="btn btn-success btn-label right ms-auto nexttab nexttab"
                                  >
                                    <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>
                                    Proceed
                                  </button>
                                </div>
                              </Form>
                            </TabPane>

                            <TabPane tabId={8}>
                              <div>
                                <h5>Accept terms and conditions</h5>
                                {/* <p className="text-muted"> </p> */}
                              </div>

                              <Col xxl={12} lg={12}>
                                <Card>
                                  {/* <PreviewCardHeader title="Default Accordion" /> */}

                                  <CardBody>
                                    <div className="live-preview">
                                      <Accordion id="default-accordion-example">
                                        <AccordionItem>
                                          <h2 className="accordion-header" id="headingOne">
                                            <button
                                              className={classnames("accordion-button", { collapsed: true })}
                                              type="button"
                                              style={{ cursor: "pointer" }}
                                            >
                                              Loan interest
                                            </button>
                                          </h2>

                                          <Collapse isOpen={true} className="accordion-collapse" id="collapseOne">
                                            <div className="accordion-body">
                                              Interest on this loan is {loanInterestRate} %
                                            </div>
                                          </Collapse>
                                        </AccordionItem>
                                        <AccordionItem>
                                          <h2 className="accordion-header" id="headingTwo">
                                            <button
                                              className={classnames("accordion-button", { collapsed: true })}
                                              type="button"
                                              style={{ cursor: "pointer" }}
                                            >
                                              Loan defaultment
                                            </button>
                                          </h2>

                                          <Collapse isOpen={true} className="accordion-collapse">
                                            <div className="accordion-body">
                                              Loans not paid on time attracts a {interestOverdueRate} % increment fee
                                            </div>
                                          </Collapse>
                                        </AccordionItem>
                                        <AccordionItem>
                                          <h2 className="accordion-header" id="headingThree">
                                            <button
                                              className={classnames("accordion-button", { collapsed: true })}
                                              type="button"
                                              style={{ cursor: "pointer" }}
                                            >
                                              Increase credit score
                                            </button>
                                          </h2>
                                          <Collapse isOpen={true} className="accordion-collapse">
                                            <div className="accordion-body">
                                              Pay your loans on time to increase your credit score
                                            </div>
                                          </Collapse>
                                        </AccordionItem>
                                      </Accordion>
                                    </div>
                                    <div className="d-none code-view">
                                      <pre className="language-markup" style={{ height: "275px" }}>
                                        <code>{/* <DefaultAccordionExample /> */}</code>
                                      </pre>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>

                              {/*  
                               

                                <div>
                                  <h5>Tips to increare credit score</h5>
                                  <p className="text-muted">Pay loan on time to increase your credit score </p>
                                </div> */}
                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  validation.handleSubmit();
                                  return false;
                                }}
                              >
                                <div className="mb-3">
                                  <ul className="list-unstyled mb-0">
                                    <li className="d-flex mt-2">
                                      <div className="flex-grow-1">
                                        <Label className="form-check-label fs-14" htmlFor="emailNotification">
                                          Accept terms and conditions
                                        </Label>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <div className="form-check form-switch">
                                          <Input
                                            value={validation.values.isTermsAccepted}
                                            name="isTermsAccepted"
                                            className="form-check-input "
                                            type="checkbox"
                                            role="switch"
                                            id="isTermsAccepted"
                                            onChange={validation.handleChange}
                                            invalid={
                                              validation.touched["isTermsAccepted"] &&
                                              validation.errors["isTermsAccepted"]
                                                ? true
                                                : false
                                            }
                                          />

                                          {validation.errors["isTermsAccepted"] ? (
                                            <FormFeedback type="invalid">
                                              {validation.errors["isTermsAccepted"]}
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>

                                <div className="d-flex align-items-start gap-3 mt-4">
                                  <button
                                    type="button"
                                    className="btn btn-light btn-label previestab"
                                    onClick={() => {
                                      toggleVerticalTab(activeVerticalTab - 1);
                                    }}
                                  >
                                    <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> go back
                                  </button>
                                  <button type="submit" className="btn btn-success btn-label right ms-auto nexttab">
                                    <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>

                                    {validation.isSubmitting ? "PLEASE WAIT" : "SUBMIT LOAN REQUEST"}
                                  </button>
                                </div>
                              </Form>
                            </TabPane>

                            <TabPane tabId={10}>
                              <div className="text-center pt-4 pb-2">
                                <div className="mb-4">
                                  <lord-icon
                                    src="https://cdn.lordicon.com/lupuorrc.json"
                                    trigger="loop"
                                    colors="primary:#0ab39c,secondary:#405189"
                                    style={{ width: "120px", height: "120px" }}
                                  ></lord-icon>
                                </div>
                                <h5>Your Order is Completed !</h5>
                                <p className="text-muted">
                                  You Will receive an order confirmation email with details of your order.
                                </p>
                              </div>
                            </TabPane>
                          </TabContent>
                        </div>
                      </Col>

                      <Col lg={3}>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h5 className="fs-14 text-primary mb-0 d-flex align-items-center">
                            <i className="ri-open-arm-line step-icon me-2 h3"></i>
                            Loan information
                          </h5>
                          {/* <span className="badge bg-danger rounded-pill">3</span> */}
                        </div>
                        <ul className="list-group mb-3">
                          <li className="list-group-item d-flex justify-content-between lh-sm">
                            <div>
                              {loanValidation.values.amount ? (
                                <i className="ri-close-circle-fill step-icon me-2 position-absolute text-success"></i>
                              ) : (
                                <i className="ri-close-circle-fill step-icon me-2 position-absolute"></i>
                              )}

                              <h6 className="my-0 mx-3">Loan amount</h6>
                              <small className="text-muted mx-3">
                                {user?.currency_symbol} {loanValidation.values.amount}
                              </small>
                            </div>
                          </li>

                          <li className="list-group-item d-flex justify-content-between lh-sm">
                            <div>
                              {loanInterestRate ? (
                                <i className="ri-close-circle-fill step-icon me-2 position-absolute text-success"></i>
                              ) : (
                                <i className="ri-close-circle-fill step-icon me-2 position-absolute"></i>
                              )}

                              <h6 className="my-0 mx-3">Loan interest rate</h6>
                              <small className="text-muted mx-3">{loanInterestRate} %</small>
                            </div>
                          </li>

                          <li className="list-group-item d-flex justify-content-between lh-sm">
                            <div>
                              {repayMentAmount ? (
                                <i className="ri-close-circle-fill step-icon me-2 position-absolute text-success"></i>
                              ) : (
                                <i className="ri-close-circle-fill step-icon me-2 position-absolute"></i>
                              )}

                              <h6 className="my-0 mx-3">Total repayment amount</h6>
                              <small className="text-muted mx-3">
                                {" "}
                                {user?.currency_symbol} {repayMentAmount}
                              </small>
                            </div>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* modal Delete Address */}
      <Modal
        isOpen={deletemodal}
        role="dialog"
        autoFocus={true}
        centered
        id="removeItemModal"
        toggle={toggledeletemodal}
      >
        <ModalHeader
          toggle={() => {
            setDeleteModal(!deletemodal);
          }}
        ></ModalHeader>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Address ?</p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => {
                setDeleteModal(!deletemodal);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger"
              onClick={() => {
                setDeleteModal(!deletemodal);
              }}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* modal Add Address */}
      <Modal isOpen={modal} role="dialog" autoFocus={true} centered id="addAddressModal" toggle={togglemodal}>
        <ModalHeader
          toggle={() => {
            setModal(!modal);
          }}
        >
          <h5 className="modal-title" id="addAddressModalLabel">
            Address
          </h5>
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="mb-3">
              <Label for="addaddress-Name" className="form-label">
                Name
              </Label>
              <Input type="text" className="form-control" id="addaddress-Name" placeholder="Enter Name" />
            </div>

            <div className="mb-3">
              <Label for="addaddress-textarea" className="form-label">
                Address
              </Label>
              <textarea
                className="form-control"
                id="addaddress-textarea"
                placeholder="Enter Address"
                rows="2"
              ></textarea>
            </div>

            <div className="mb-3">
              <Label for="addaddress-Name" className="form-label">
                Phone
              </Label>
              <Input type="text" className="form-control" id="addaddress-Name" placeholder="Enter Phone No." />
            </div>

            <div className="mb-3">
              <Label for="state" className="form-label">
                Address Type
              </Label>
              <select className="form-select" id="state" data-plugin="choices">
                <option value="homeAddress">Home (7am to 10pm)</option>
                <option value="officeAddress">Office (11am to 7pm)</option>
              </select>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              setModal(!modal);
            }}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => {
              setModal(!modal);
            }}
          >
            Save
          </button>
        </ModalFooter>
      </Modal>

      <ProccessingLoader alert={alert} setAlert={setAlert} />

      {errMsg ? <MsgToast msg={errMsg} color="danger" icon="ri-checkbox-circle-line" /> : null}

      {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}
    </React.Fragment>
  );
};

export default CreateLoan;
