import React, { useState } from "react";

//Import Breadcrumb
import Cleave from "cleave.js/react";

import { Form, FormFeedback, Row, Col, Card, Label, Input, Button, Spinner, ModalFooter } from "reactstrap";

import { Link } from "react-router-dom";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

import { APIClient } from "../../../utils/api_helper";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import SendMoneyModal from "../SendMoneyModal";
import { useSelector } from "react-redux";

const api = new APIClient();

const SimplyTab = () => {
  document.title = "";

  const [errMsg, setErrorMsg] = useState("");

  const [simplyRecipient, setSimplyRecipient] = useState({});

  const [isModalActive, setIsModalActive] = useState(false);

  // validation
  const simplyValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      phone: "",
      amount: "",
    },

    validationSchema: Yup.object({
      phone: Yup.string().required("Please enter a reciepient phone"),
      amount: Yup.string().required("Please enter a amount"),
    }),

    onSubmit: async (values) => {
      console.log("sending_money_SIMPLY", values);

      setErrorMsg("");
      // setRequestSuccess("");
      setSimplyRecipient({});

      try {
        const { data } = await api.get("/users/user/search/", values);

        console.log(data, "USERRR_OBJECTS");

        // setRequestSuccess(data.message);
        setSimplyRecipient(data);
        setIsModalActive(true);
      } catch (error) {
        console.log(error, "FUND_MOMO_ERROR");

        let errMsg = error?.response?.data?.message;

        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";

        setErrorMsg(errMsg);

        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  console.log(simplyValidation.values, "S__VALUESS");

  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          simplyValidation.handleSubmit();
          return false;
        }}
      >
        <div className="collapse show" id="paymentmethodCollapse">
          <Card className="p-4 border shadow-none mb-0 mt-4">
            <Row className="gy-3">
              <Col md={6}>
                <Label htmlFor="cc-name" className="form-label">
                  Amount
                </Label>
                <div className="input-group mb-3">
                  <span className="input-group-text">#</span>

                  <Cleave
                    className="form-control bg-light"
                    aria-label="Dollar amount (with dot and two decimal places)"
                    placeholder="0.00"
                    minLength={10}
                    maxLength={15}
                    onChange={simplyValidation.handleChange}
                    onBlur={simplyValidation.handleBlur}
                    value={simplyValidation.values.amount || ""}
                    invalid={simplyValidation.touched.amount && simplyValidation.errors.amount ? true : false}
                    options={{
                      numeral: true,
                      numeralThousandsGroupStyle: "thousand",
                    }}
                    name="amount"
                  />

                  {simplyValidation.touched.amount && simplyValidation.errors.amount ? (
                    <FormFeedback type="invalid">
                      <div>{simplyValidation.errors.amount}</div>
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col md={6}>
                <Label htmlFor="cc-name" className="form-label">
                  Phone number
                </Label>
                <div className="input-group mb-3">
                  <span className="input-group-text">#</span>

                  <Input
                    type="text"
                    className="form-control bg-light"
                    aria-label="Dollar amount (with dot and two decimal places)"
                    placeholder="Enter user phone number"
                    //
                    name="phone"
                    onChange={simplyValidation.handleChange}
                    onBlur={simplyValidation.handleBlur}
                    value={simplyValidation.values.phone || ""}
                    invalid={simplyValidation.touched.phone && simplyValidation.errors.phone ? true : false}
                  />
                </div>
                {simplyValidation.touched.password && simplyValidation.errors.password ? (
                  <FormFeedback type="invalid">
                    <div>{simplyValidation.errors.password}</div>
                  </FormFeedback>
                ) : null}
                <small className="text-muted">make sure to verify the number</small>
              </Col>
            </Row>
          </Card>
          <div className="text-muted mt-2 fst-italic">
            <i data-feather="lock" className="text-muted icon-xs"></i> Your transaction is secured with SSL encryption
          </div>
        </div>

        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            {simplyValidation.isSubmitting ? (
              <Button color="btn btn-primary" className="btn-load">
                <span className="d-flex align-items-center">
                  <span className="flex-grow-1 me-2">Loading...</span>
                  <Spinner size="sm" type="grow" className="flex-shrink-0" role="status">
                    {" "}
                    Loading...{" "}
                  </Spinner>
                </span>
              </Button>
            ) : (
              <button type="submit" className="btn btn-success btn-label right ms-auto nexttab">
                <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>
                Proceed
              </button>
            )}
          </div>
        </ModalFooter>

        {errMsg ? <MsgToast msg={errMsg} color="danger" icon="ri-checkbox-circle-line" /> : null}
      </Form>

      <SendMoneyModal
        isModalOpen={isModalActive}
        simplyRecipient={simplyRecipient}
        simplyValidation={simplyValidation}
        toggleModal={() => setIsModalActive(!isModalActive)}
      />
    </React.Fragment>
  );
};

export default SimplyTab;
