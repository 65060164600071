import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { UncontrolledCollapse, CardFooter, Button, UncontrolledAlert } from "reactstrap";

import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import SwiperCore, { Autoplay } from "swiper";

// import { profileSuccess } from "../../store/actions";
// import { useDispatch } from "react-redux";
import CountUp from "react-countup";
import useSWR from "swr";

import { APIClient } from "../../utils/api_helper";
import profileBg from "../../assets/images/profile-bg.jpg";
import LoanModal from "./Modals/LoanModal";
import RepayLoanModal from "./Modals/RepayLoanModal";

const api = new APIClient();

const getUserLoan = async (url) => {
  const { data } = await api.get(url);
  return data;
};

const UserInformation = () => {
  SwiperCore.use([Autoplay]);

  let { data } = useSWR(`/loan/info/`, getUserLoan, {});

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));
  const [openLoanModal, setOpenLoanModal] = useState(false);
  const [openRepayLoanModal, setOpenRepayLoanModal] = useState(false);

  console.log(data, "USER__LOANN_DATAA");

  let score_percentage = (data?.userCreditScore / 850) * 100;

  document.title = "Profile";

  return (
    <React.Fragment>
      <div className="profile-foreground position-relative mx-n4 mt-n4">
        <div className="profile-wid-bg">
          <img src={profileBg} alt="" className="profile-wid-img" />
        </div>
      </div>
      <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
        <Row className="g-4">
          <Col lg={12}>
            <Row className="gx-lg-5">
              <Col xl={8} md={8} className="mx-auto">
                <Card>
                  <CardBody>
                    <div className="product-img-slider sticky-side-div">
                      <Card>
                        <CardBody>
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3">
                                <i className={"align-middle ri-money-dollar-circle-fill"}></i>
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-3">Simply Credit Score</p>
                              <div className="progress animated-progress custom-progress progress-label ">
                                <div
                                  className="progress-bar bg-danger"
                                  role="progressbar"
                                  style={{ width: `${score_percentage}%` }}
                                  aria-valuenow="30"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <div className="label">{data?.userCreditScore} / 850</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col xl={12} md={12}>
                              <Row className="d-flex align-items-center ">
                                <Col xl={6} md={6} className="d-flex align-items-center ">
                                  <div className="avatar-sm flex-shrink-0">
                                    <span className="avatar-title bg-light text-primary rounded-circle fs-3">
                                      <i className={"align-middle ri-money-dollar-circle-fill"}></i>
                                    </span>
                                  </div>

                                  <div>
                                    <p className="text-uppercase fw-semibold fs-6 text-muted mb-1">
                                      My outstanding Loan Balance
                                    </p>
                                    <h4 className=" mb-0">
                                      {" "}
                                      <CountUp
                                        start={0}
                                        end={data?.userLoanBalance}
                                        decimals={2}
                                        separator={","}
                                        prefix={user?.currency_symbol || ""}
                                        duration={3}
                                      />
                                    </h4>
                                  </div>
                                </Col>

                                {data?.has_active_loan && (
                                  <Col xl={6} md={6} className="d-flex justify-content-end ">
                                    <Button
                                      onClick={() => setOpenRepayLoanModal(!openRepayLoanModal)}
                                      color="primary"
                                      className="btn-sm w-20"
                                    >
                                      <Link to="/loan/repayment" className={"text-decoration-underline "}>
                                        <i className="ri-question-answer-line align-bottom me-1"></i>
                                        repay loan
                                      </Link>
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                            </Col>

                            {/* {data?.has_active_loan && (
                                <Col xl={6} md={6}>
                                  <Button
                                    onClick={() => setOpenRepayLoanModal(!openRepayLoanModal)}
                                    color="primary"
                                    className="btn-sm w-20"
                                  >
                                    <Link to="/loan/repayment" className={"text-decoration-underline "}>
                                      <i className="ri-question-answer-line align-bottom me-1"></i>
                                      repay loan
                                    </Link>
                                  </Button>
                                </Col>
                              )} */}
                          </Row>
                        </CardBody>

                        {/* <Link to="/loan/repayment" className={"text-center color-primary text-decoration-underline "}>
                          <i className="ri-question-answer-line align-bottom me-1"></i>
                          repay loan
                        </Link> */}

                        {data?.has_active_loan && (
                          <p className="text-center">
                            payment due <b> {data?.current_loan_due_Date}</b>
                          </p>
                        )}
                      </Card>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {/* /////////////////////////////////////////////////////////////// */}
              <Col xl={12} md={12}>
                <UncontrolledAlert color="primary" className="text-center">
                  <strong>Use SImply services and pay your loans on time to</strong> <b>boost</b> your credit score
                </UncontrolledAlert>
              </Col>

              <Col xl={6} md={6}>
                <Card className={"mb-1 ribbon-box ribbon-fill ribbon-sm"}>
                  <CardBody>
                    <Link to="#" className="d-flex align-items-center" id={"leadInnerDiscovered"}>
                      <div className="avatar-sm flex-shrink-0">
                        <span className={"avatar-title rounded fs-3 bg-soft-success"}>
                          <i className={`text-success} bx bx-dollar-circle`}></i>
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6 className="fs-14 mb-1">Apply for a new loan</h6>
                      </div>
                    </Link>
                  </CardBody>
                  <UncontrolledCollapse
                    className="border-top border-top-dashed"
                    toggler={"#leadInnerDiscovered"}
                    defaultOpen={true}
                  >
                    <Button onClick={() => setOpenLoanModal(!openLoanModal)} color="primary" className="btn-sm w-100">
                      <i className="ri-question-answer-line align-bottom me-1"></i> Apply
                    </Button>
                  </UncontrolledCollapse>
                </Card>
              </Col>
              <Col xl={6} md={6}>
                <Card className={"mb-1 ribbon-box ribbon-fill ribbon-sm"}>
                  <CardBody>
                    <Link className="d-flex align-items-center" id={"leadInnerDiscovered"}>
                      <div className="avatar-sm flex-shrink-0">
                        <span className={"avatar-title rounded fs-3 bg-soft-success"}>
                          <i className={`text-success} bx bx-dollar-circle`}></i>
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6 className="fs-14 mb-1">View loan history</h6>
                      </div>
                    </Link>
                  </CardBody>
                  <UncontrolledCollapse
                    className="border-top border-top-dashed"
                    toggler={"#leadInnerDiscovered"}
                    defaultOpen={true}
                  >
                    <Link to="/loan/list" className="d-flex align-items-center">
                      <Button color="info" className="btn-sm w-100">
                        <i className="ri-question-answer-line align-bottom me-1"></i> view loan history
                      </Button>
                    </Link>
                  </UncontrolledCollapse>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <LoanModal openModal={openLoanModal} setOpenModal={setOpenLoanModal} />
    </React.Fragment>
  );
};

export default UserInformation;
